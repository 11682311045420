export var LobbyEvent;
(function (LobbyEvent) {
    LobbyEvent["CreateLobby"] = "CreateLobby";
    LobbyEvent["Error"] = "Error";
    LobbyEvent["SendOptions"] = "SendOptions";
    LobbyEvent["PlayerWin"] = "PlayerWin";
    LobbyEvent["ClearWinner"] = "ClearWinner";
    LobbyEvent["PutEntity"] = "PutEntity";
    LobbyEvent["UpdateLatestLobbies"] = "UpdateLatestLobbies";
    LobbyEvent["UpdateWorldMap"] = "UpdateWorldMap";
    LobbyEvent["UpdateTimeout"] = "UpdateTimeout";
    LobbyEvent["UpdateStep"] = "UpdateStep";
    LobbyEvent["UpdatePlayers"] = "UpdatePlayers";
})(LobbyEvent || (LobbyEvent = {}));
