import React from "react";
import { Container, Checkbox, Label } from "./styled";
export const InputCheckbox = ({ label, value, name, tooltip, onChange, }) => {
    const handleChange = (e) => {
        onChange(e.target.name, e.target.checked);
    };
    return (React.createElement(Container, { title: tooltip, "aria-label": label },
        React.createElement(Checkbox, { defaultChecked: value, name: name, onChange: handleChange }),
        React.createElement(Label, null, label)));
};
