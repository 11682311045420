import React, { useMemo } from "react";
import { Status, WaitingOverlay, Loading } from "./styled";
export const Waiting = ({ currentPlayers, maxPlayers }) => {
    const blocks = useMemo(() => Array(maxPlayers).fill(0), [maxPlayers]);
    return (React.createElement(WaitingOverlay, null, maxPlayers && (React.createElement(React.Fragment, null,
        React.createElement(Loading, null, blocks.map((_, slot) => (React.createElement(Loading.Block, { key: slot, slot: slot, empty: slot + 1 > currentPlayers })))),
        React.createElement(Status, null,
            React.createElement(Status.String, null, "Joined"),
            React.createElement(Status.Number, null, currentPlayers),
            React.createElement(Status.String, null, "from"),
            React.createElement(Status.Number, null, maxPlayers),
            React.createElement(Status.String, null, "players"))))));
};
