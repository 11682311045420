import React, { useState, useEffect } from "react";
import { useSocketContext } from "~/client/socket/hooks/use-socket-context";
import { LobbyEvent } from "~/shared/lobby/types";
import { Title, LobbyList, Lobby } from "./styled";
export const Lobbies = () => {
    const [lobbies, setLobbies] = useState([]);
    const socket = useSocketContext();
    useEffect(() => {
        socket.on(LobbyEvent.UpdateLatestLobbies, setLobbies);
        return () => {
            socket.off(LobbyEvent.UpdateLatestLobbies, setLobbies);
        };
    }, []);
    return lobbies.length > 0 ? (React.createElement(React.Fragment, null,
        React.createElement(Title, null, "Or select existing"),
        React.createElement(LobbyList, null, lobbies.map((lobby) => (React.createElement(Lobby, { key: lobby.uuid, href: `/game/${lobby.uuid}`, target: "_blank" },
            React.createElement(Lobby.Name, null, lobby.uuid),
            React.createElement(Lobby.OnlineWrapper, null,
                React.createElement(Lobby.OnlineValue, null, lobby.players.online),
                " /",
                " ",
                React.createElement(Lobby.OnlineValue, null, lobby.players.max)))))))) : null;
};
