import React, { useState } from "react";
import GitHubButton from "react-github-btn";
import { Editor } from "./editor";
import { Lobbies } from "./lobbies";
import { HomeSection } from "./types";
import { Button } from "~/client/components/button";
import { Container, Logotype, Description, Footer } from "./styled";
export const HomePage = () => {
    const [section, setSection] = useState(HomeSection.Lobbies);
    const openLobbies = () => setSection(HomeSection.Lobbies);
    const openEditor = () => setSection(HomeSection.Editor);
    return (React.createElement(Container, null,
        React.createElement(Logotype, { onClick: () => setSection(HomeSection.Lobbies) },
            React.createElement(Logotype.Blocks, null,
                React.createElement(Logotype.Block, null),
                React.createElement(Logotype.Block, null),
                React.createElement(Logotype.Block, null)),
            React.createElement(Logotype.Label, null, "DOTHREE")),
        React.createElement(Description, null, "Online logic game, which like a mix of tetris and tic-tac-toe"),
        section === HomeSection.Editor ? (React.createElement(Editor, { onClose: openLobbies })) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: openEditor }, "Create lobby"),
            React.createElement(Lobbies, null))),
        React.createElement(Footer, null,
            React.createElement(GitHubButton, { href: "https://github.com/neki-dev/dothree", "data-color-scheme": "no-preference: dark; light: dark; dark: dark;", "data-size": "large", "data-show-count": "true", "aria-label": "Star neki-dev/dothree on GitHub", "data-icon": "octicon-star" }, "Star"),
            React.createElement(GitHubButton, { href: "https://github.com/neki-dev", "data-color-scheme": "no-preference: dark; light: dark; dark: dark;", "data-size": "large", "data-show-count": "true", "aria-label": "Follow @neki-dev on GitHub" }, "Follow"))));
};
