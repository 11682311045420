import React, { useState } from "react";
import { Button } from "~/client/components/button";
import { InputCheckbox } from "~/client/components/input-checkbox";
import { InputRange } from "~/client/components/input-range";
import { useSocketContext } from "~/client/socket/hooks/use-socket-context";
import { LOBBY_DEFAULT_OPTIONS } from "~/shared/lobby/const";
import { LobbyEvent } from "~/shared/lobby/types";
import { Form, Settings } from "./styled";
export const Editor = ({ onClose }) => {
    const [options, setOptions] = useState(LOBBY_DEFAULT_OPTIONS);
    const socket = useSocketContext();
    const onChange = (name, value) => {
        setOptions((currentOptions) => (Object.assign(Object.assign({}, currentOptions), { [name]: value })));
    };
    const createLobby = (event) => {
        event.preventDefault();
        socket.emit(LobbyEvent.CreateLobby, options, (uuid) => {
            window.open(`/game/${uuid}`);
            onClose === null || onClose === void 0 ? void 0 : onClose();
        });
    };
    return (React.createElement(Form, null,
        React.createElement(Settings, null,
            React.createElement(InputRange, { label: "Max players", name: "maxPlayers", defaultValue: options.maxPlayers, min: 2, max: 5, onChange: onChange, tooltip: "Number of players required to start game" }),
            React.createElement(InputRange, { label: "Map density", name: "density", defaultValue: options.density, min: 0, max: 4, onChange: onChange, tooltip: "World cubes spawn rate" }),
            React.createElement(InputRange, { label: "Step timeout", name: "timeout", defaultValue: options.timeout, min: 5, max: 60, step: 5, onChange: onChange, tooltip: "Number of seconds allocated per step" }),
            React.createElement(InputCheckbox, { label: "Use bonuses", name: "useBonuses", value: options.useBonuses, onChange: onChange }),
            options.useBonuses && (React.createElement(InputRange, { label: "Bonus frequency", name: "bonusing", defaultValue: options.bonusing, min: 1, max: 5, onChange: onChange, tooltip: "Bonus spawn rate" })),
            React.createElement(InputCheckbox, { label: "Movable map", name: "moveMap", value: options.moveMap, onChange: onChange, tooltip: "With a moving map, after each step, there is a shift one cell to left" })),
        React.createElement(Button, { onClick: createLobby }, "Accept")));
};
