import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Countdown } from "./countdown";
import { useSocketContext } from "~/client/socket/hooks/use-socket-context";
import { LOBBY_DEFAULT_OPTIONS } from "~/shared/lobby/const";
import { LobbyEvent } from "~/shared/lobby/types";
import { Container, Block, Player, RestartMessage, WinMessage, EmptySlot, } from "./styled";
import CONFIG from "~/../config.json";
export const InfoPanel = ({ players, options = LOBBY_DEFAULT_OPTIONS, }) => {
    const { uuid } = useParams();
    const [step, setStep] = useState();
    const [winner, setWinner] = useState(null);
    const socket = useSocketContext();
    const slots = useMemo(() => {
        const result = [];
        for (let i = 0; i < options.maxPlayers; i += 1) {
            const currentPlayer = players.find((player) => player.slot === i);
            result.push(currentPlayer !== null && currentPlayer !== void 0 ? currentPlayer : null);
        }
        return result;
    }, [players, options.maxPlayers]);
    const current = useMemo(() => players.find((player) => player.id === socket.id), [players]);
    const clearWinner = () => {
        setWinner(null);
    };
    useEffect(() => {
        socket.on(LobbyEvent.UpdateStep, setStep);
        socket.on(LobbyEvent.PlayerWin, setWinner);
        socket.on(LobbyEvent.ClearWinner, clearWinner);
        return () => {
            socket.off(LobbyEvent.UpdateStep, setStep);
            socket.off(LobbyEvent.PlayerWin, setWinner);
            socket.off(LobbyEvent.ClearWinner, clearWinner);
        };
    }, []);
    useEffect(() => {
        if (!current) {
            return undefined;
        }
        const titleIdle = `DOTHREE #${uuid}`;
        const titleActive = "Your step!";
        let interval;
        if (step === current.slot && players.length === options.maxPlayers) {
            document.title = titleActive;
            interval = setInterval(() => {
                document.title =
                    document.title === titleActive ? titleIdle : titleActive;
            }, 1000);
        }
        else {
            document.title = titleIdle;
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [step, current && current.slot, players.length]);
    return (React.createElement(Container, null,
        React.createElement(Block, null,
            React.createElement(Block.Label, null, "Players"),
            React.createElement(Block.Value, null, slots.map((player, slot) => player ? (React.createElement(Player, { key: slot, slot: slot }, current && current.slot === slot && (React.createElement(Player.SelfLabel, null, "You")))) : (React.createElement(EmptySlot, { key: slot }))))),
        step !== undefined && (React.createElement(Block, null,
            React.createElement(Block.Label, null, "Step"),
            React.createElement(Block.Value, null,
                React.createElement(Player, { slot: step }),
                React.createElement(Countdown, { key: step, limit: options.timeout, current: step === (current === null || current === void 0 ? void 0 : current.slot) })))),
        winner && (React.createElement(Block, null,
            React.createElement(Block.Label, null),
            React.createElement(Block.Value, null,
                React.createElement(WinMessage, null, winner === (current === null || current === void 0 ? void 0 : current.id) ? "You win" : "You lose"),
                React.createElement(RestartMessage, null,
                    "Restart game after ",
                    CONFIG.LOBBY_RESTART_TIMEOUT,
                    " seconds..."))))));
};
