import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppWrapper } from "./components/app-wrapper";
import { HomePage } from "./pages/home";
import { LobbyPage } from "./pages/lobby";
import { SocketProvider } from "./socket/socket-provider";
const app = document.getElementById("app");
if (!app) {
    throw Error("Undefined App element");
}
const root = createRoot(app);
root.render(React.createElement(BrowserRouter, null,
    React.createElement(AppWrapper, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(SocketProvider, { namespace: "/home" },
                    React.createElement(HomePage, null)) }),
            React.createElement(Route, { path: "/game/:uuid", element: React.createElement(SocketProvider, { namespace: "/lobby" },
                    React.createElement(LobbyPage, null)) })))));
