export var EntityType;
(function (EntityType) {
    EntityType["BLOCK"] = "block";
    EntityType["PLAYER"] = "player";
    EntityType["EMPTY"] = "empty";
    EntityType["BONUS"] = "bonus";
})(EntityType || (EntityType = {}));
export var EntityBonusType;
(function (EntityBonusType) {
    EntityBonusType["REPLACER"] = "replacer";
    EntityBonusType["SPAWN"] = "spawn";
    EntityBonusType["LASER"] = "laser";
})(EntityBonusType || (EntityBonusType = {}));
