import React, { useState, useEffect } from "react";
import { InfoPanel } from "./info-panel";
import { Waiting } from "./waiting";
import { World } from "./world";
import { useSocketContext } from "~/client/socket/hooks/use-socket-context";
import { LobbyEvent } from "~/shared/lobby/types";
import { Container, GameScreen, Error } from "./styled";
export const LobbyPage = () => {
    const [error, setError] = useState();
    const [options, setOptions] = useState();
    const [players, setPlayers] = useState([]);
    const socket = useSocketContext();
    const ready = players.length === (options === null || options === void 0 ? void 0 : options.maxPlayers);
    useEffect(() => {
        socket.on(LobbyEvent.Error, setError);
        socket.on(LobbyEvent.UpdatePlayers, setPlayers);
        socket.on(LobbyEvent.SendOptions, setOptions);
        return () => {
            socket.off(LobbyEvent.Error, setError);
            socket.off(LobbyEvent.UpdatePlayers, setPlayers);
            socket.off(LobbyEvent.SendOptions, setOptions);
        };
    }, []);
    return (React.createElement(Container, null, error ? (React.createElement(Error, null,
        React.createElement(Error.Message, null, error))) : (React.createElement(React.Fragment, null,
        !ready && (React.createElement(Waiting, { currentPlayers: players.length, maxPlayers: options === null || options === void 0 ? void 0 : options.maxPlayers })),
        React.createElement(GameScreen, null,
            React.createElement(World, { players: players }),
            React.createElement(InfoPanel, { players: players, options: options }))))));
};
