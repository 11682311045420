import { EntityType } from "~/shared/entity/types";
export function isPuttable(world, x, y) {
    const entity = world[y][x];
    if (![EntityType.EMPTY, EntityType.BONUS].includes(entity.type)) {
        return false;
    }
    if (y + 1 === world.length) {
        return true;
    }
    const entityDown = world[y + 1][x];
    return [EntityType.PLAYER, EntityType.BLOCK].includes(entityDown.type);
}
