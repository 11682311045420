import React, { useState } from "react";
import { Container, Controls, Label, Value } from "./styled";
export const InputRange = ({ label, name, defaultValue, min, max, step = 1, tooltip, onChange, }) => {
    const [value, setValue] = useState(defaultValue);
    const changeValue = (shift) => {
        const newValue = value + shift * step;
        if (newValue >= min && newValue <= max) {
            setValue(newValue);
            onChange(name, newValue);
        }
    };
    return (React.createElement(Container, { title: tooltip, "aria-label": label },
        React.createElement(Label, null, label),
        React.createElement(Controls, null,
            React.createElement(Controls.Dec, { onClick: () => changeValue(-1) }),
            React.createElement(Value, { small: max >= 10 }, value),
            React.createElement(Controls.Inc, { onClick: () => changeValue(+1) }))));
};
